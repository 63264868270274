import React, { useState } from 'react';
import '../styles/Projects.css';
import capitalcoinagepng from '../assets/Capitalcoinage.png';
import financialtechhubpng from '../assets/Finacialtechhub.png';
import Wordpressai from '../assets/Wordpressai.png';

const Projects = () => {
  const [activeProjectIndex, setActiveProjectIndex] = useState(null);

  const projects = [
    {
      name: 'Capital Coinage',
      url: 'https://capitalcoinage.com',
      image: capitalcoinagepng,
      description: 'A website focused on Finance insights.'
    },
    {
      name: 'Financial Tech Hub',
      url: 'https://financialtechhub.com',
      image: financialtechhubpng,
      description: 'A platform for financial technology news and analysis.',
    },
    {
      name: 'Wordpress AI',
      url: 'https://github.com/tijnski/WordpressAI',
      image: Wordpressai,
      description: 'An AI-powered tool for SEO-optimized blog posts.'
    }
    // Add more projects as needed
  ];

  const toggleActiveProject = (index) => {
    if (activeProjectIndex === index) {
      setActiveProjectIndex(null); // Clicking the same project again will close it
    } else {
      setActiveProjectIndex(index);
    }
  };

  return (
    <div className="projects-wrapper">
      <div className="project-list">
        {projects.map((project, index) => (
        <div 
          key={index}
          onClick={() => toggleActiveProject(index)}
          className="project-item"
        >
          {project.name}
          <i className={`dropdown-icon fas ${activeProjectIndex === index ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
        </div>
        ))}
      </div>
      {activeProjectIndex !== null && (
        <div className="project-details">
          <img src={projects[activeProjectIndex].image} alt={projects[activeProjectIndex].name} className="project-image" />
          <h3>{projects[activeProjectIndex].name}</h3>
          <p>{projects[activeProjectIndex].description}</p>
          <a href={projects[activeProjectIndex].url} target="_blank" rel="noopener noreferrer">Visit Project</a>
        </div>
      )}
    </div>
  );
}

export default Projects;