import React from 'react';
import '../styles/Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  return (
    <div className="contact-container">
      <h2>Connect With Me</h2>
      <p>If you're interested in collaborating, networking, or just want to chat, feel free to reach out through any of the platforms below. I look forward to connecting with you!</p>
      <div className="contact-content">
        <a href="https://www.linkedin.com/in/tijn-hoorneman-87897826b/" target="_blank" rel="noopener noreferrer" className="contact-button">
          <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
        </a>
        <a href="mailto:tijn@hoorneman.com" className="contact-button">
          <FontAwesomeIcon icon={faEnvelope} /> Email Me
        </a>
      </div>
    </div>
  );
}

export default Contact;