import React from 'react';
import '../styles/Home.css';
import Mepicture from '../assets/Me.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const handleArrowClick = () => {
    const scrollDistance = window.innerHeight;
    window.scrollBy({ top: scrollDistance, left: 0, behavior: 'smooth' });
  };
  return (
    <div className="home-container">
      <div className="tijn-section">
        <img src={Mepicture} alt="Tijn Hoorneman" className="profile-image" />
        <h2>Tijn Hoorneman</h2>
      </div>
      <div className="intro">
        <h1>Welcome to My Portfolio</h1>
        <p>Explore my work and projects.</p>
        <div className="arrow-container" onClick={handleArrowClick}>
          <FontAwesomeIcon icon={faArrowDown} className="arrow-down" />
        </div>
      </div>
    </div>
  );
}

export default Home;