import React, { useEffect } from 'react';
import Mepicture from '../assets/Me.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft, faPython } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/About.css';

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration
    });
  }, []);

  const workExperience = [
    {
      title: 'Software Developer at Justins',
      start: '2022-12',
      end: 'Present',
      location: 'Arnhem-Nijmegen Region, On-site',
      skills: 'Software Development',
    },
    {
      title: 'Delivery Person at Domino\'s',
      start: '2022-11',
      end: 'Present',
      location: 'Dieren, Gelderland, Netherlands, On-site',
    },
    {
      title: 'Salesperson at Talk2Marketing',
      start: '2021-01',
      end: '2022-11',
      location: 'Arnhem-Nijmegen Region, On-site',
      skills: 'Sales, Communication, Lead Generation, Door-to-Door Sales',
    },
    {
      title: 'Shelf Stocker at Albert Heijn',
      start: '2019-10',
      end: '2021-10',
      location: 'Dieren, Gelderland, Netherlands, On-site',
    },
  ];

  const calculateDuration = (start, end) => {
    const [startYear, startMonth] = start.split('-').map(Number);
    const [endYear, endMonth] = end === 'Present' ? [new Date().getFullYear(), new Date().getMonth() + 1] : end.split('-').map(Number);

    // Adjust month for inclusive duration
    let months = endMonth - startMonth + 1;
    let years = endYear - startYear;

    if (months <= 0) {
      years -= 1;
      months += 12; // Adjust for the full year cycle
    }

    const yearText = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
    const monthText = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
    return [yearText, monthText].filter(Boolean).join(' ');
  };

  return (
    <div className="about-container">
      <div className="profile-section" data-aos="fade-right">
        <img src={Mepicture} alt="Profile" className="profile-image" />
        <h2>Tijn Hoorneman</h2>
      </div>
      <div className="work-experience" data-aos="fade-left">
        <h3>Work Experience</h3>
        {workExperience.map((exp, index) => (
          <div className="experience-item" key={index}>
            <h4>{exp.title}</h4>
            <p>{`${exp.start} - ${exp.end} (${calculateDuration(exp.start, exp.end)})`}</p>
            <p>Location: {exp.location}</p>
            {exp.skills && <p>Skills: {exp.skills}</p>}
          </div>
        ))}
      </div>
      <div className="certifications" data-aos="fade-up">
        <h3>Certifications</h3>
        <ul>
          <li><FontAwesomeIcon icon={faMicrosoft} /> Microsoft SC-900</li>
          <li><FontAwesomeIcon icon={faMicrosoft} /> Microsoft PL-900</li>
          <li><FontAwesomeIcon icon={faMicrosoft} /> Microsoft MS-900</li>
          <li><FontAwesomeIcon icon={faPython} /> ITS Python</li>
        </ul>
      </div>
    </div>
  );
};

export default About;